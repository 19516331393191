import React, { createContext, useState } from 'react';

export const CommonVariableContext = createContext();

export const CommonVariableProvider = ({ children }) => {
    
  const [commonVariable, setCommonVariable] = useState(null);

  return (
    <CommonVariableContext.Provider value={{ commonVariable, setCommonVariable }}>
      {children}
    </CommonVariableContext.Provider>
  );
};