import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { gbData } from '../../globalVarbs';
import { PrimeReactProvider } from 'primereact/api';
import "primeflex/primeflex.css";
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';


import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';

export default function PackageInformationIncluded( props ) {

  let { package_type } = useParams();
  let { package_list_slug } = useParams();
  const imageRef = useRef();
  const [listIncludeInfor, setListIncludeInfor] = useState("");
  const [inclImageCst, setInclImageCst] = useState("");
  const [includeText, setIncludeText] = useState("");
  const [loading, setLoading] = useState(false);

  const toast = useRef(null);
  const showT = (severity = 'success', summary = 'failed', toastMessage = '') => {
    toast.current.show({ severity: severity, summary: summary, detail: toastMessage });
  };
  const isFormFieldInvalid = (name) => !!(formikINCL.touched[name] && formikINCL.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error">{formikINCL.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
  };

  const fetchPckgIncludeInfor = async () => {
    try {
      const response = await fetch( gbData.API_URL + '/admin-get-package-include-information', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "pckg_list_slug": package_list_slug
        }),
      });

      if ( !response.ok ) {
        showT( 'error', 'Failed', `API request failed with status ${response.status}` );
        throw new Error( `API request failed with status ${response.status}` );
      }

      const respData = await response.json();
      // console.log('API response:', respData);
      setListIncludeInfor(respData.data)
      // Handle successful API response here
    } catch (error) {
      alert(error)
      console.error( 'Error:', error );
      // Handle errors here
    } finally {
      // Optionally, clear the form after submission
      //   setFormData([{ id: 1, image: null, text: '' }]);
    }
  }

  const formikINCL = useFormik({
    initialValues: {
      inclImage: '',
      inclText: '',
    },
    validate: ( data ) => {
      let errorsUpd = {};

      if ( !data.inclImage ) {
        errorsUpd.inclImage = 'Include Image is required.';
      }

      if ( !data.inclText ) {
        errorsUpd.inclText = 'Include Text is required.';
      }

      return errorsUpd;
    },
    onSubmit: ( data ) => {
      // data && showT(data);
      // console.log(data);
      setLoading( true );
      const formDataToSend = new FormData();
      formDataToSend.append( 'pckgListSlug', package_list_slug );
      formDataToSend.append( 'pckgListPckgType', package_type );
      formDataToSend.append( 'incl_image', inclImageCst );
      formDataToSend.append( 'include_text', data.inclText );
      //   return;

      fetch( gbData.API_URL + '/admin-create-package-include-information', {
        method: 'POST',
        body: formDataToSend
      })
      .then( response => {
        if (!response.ok) {
          showT('error', 'Failed', 'Network response was not ok' );
          throw new Error( 'Network response was not ok' );
          setLoading(false);
        }
        return response.json();
      })
      .then(ApiResponse => {
        // Process the newly created user data
        // console.log('ApiResponse:', ApiResponse);
        if (ApiResponse.success == 0) {
          showT('error', 'Failed', ApiResponse.message );
          setLoading(false);
          return;
        }
        //   setVisible(false);
        formikINCL.resetForm();
        showT('success', 'Success', ApiResponse.message );
        setLoading(false);
        fetchPckgIncludeInfor();
      })
      .catch(error => {
        alert(error)
        // console.error('Error:', error);
        setLoading(false);
      });
      formikINCL.resetForm();
    }
  });


  const deleteIncludeInfor = async (e,rowData) => {
    console.group(rowData);
    e.target.innerHTML = "Deleting...";
    const formDataToSend = new FormData();
    formDataToSend.append('id', rowData.id);

    try {
      const response = await fetch( gbData.API_URL + '/admin-delete-package-include-information', {
        method: 'POST',
        body: formDataToSend,
      });

      if (!response.ok) {
        showT('error', 'Failed', `API request failed with status ${response.status}` );
        throw new Error( `API request failed with status ${response.status}` );
        e.target.innerHTML = "Delete";
      }

      const data = await response.json();
      console.log('API response:', data);
      showT('success', 'Success', data.message);      
      fetchPckgIncludeInfor();

      // Handle successful API response here
    } catch (error) {
      alert(error)
      // console.error('Error:', error);
      e.target.innerHTML = "Delete";
      // Handle errors here
    } finally {
      // Optionally, clear the form after submission
      // setFormData({ image: '', text: '' });
    }
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button label="Delete" outlined className="mr-1" onClick={(e) => deleteIncludeInfor(e,rowData)} />
      </React.Fragment>
    );
  };

  useEffect(() => {
    fetchPckgIncludeInfor();
  }, [])

  return (

    <PrimeReactProvider>
      <Toast ref={toast} />
      <DataTable value={listIncludeInfor} tableStyle={{ minWidth: '50rem' }}>
        <Column field="includeText" header="IncludeText"></Column>
        <Column field={props.showImageTemplate} header="Icon"></Column>
        <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
      </DataTable>
      {/* <form onSubmit={handleSubmit}> */}
      <form onSubmit={formikINCL.handleSubmit} className='edit-form-secod mt-40 mb-40'>
        <div className='form-field-group'>
          <div className='form-left-sec'>
            <label htmlFor="image">Image: </label><br />
            <input
              type="file"
              id="incl-Image"
              name="inclImage"
              value={formikINCL.values.inclImage}
              onChange={(e) => {
                formikINCL.setFieldValue('inclImage', e.target.value);
                setInclImageCst(e.currentTarget.files[0])
              }}
              className={classNames('w-full mb-3 p-inputtext', { 'p-invalid': isFormFieldInvalid('inclImage') })}
              accept="image/jpeg, image/png, image/gif"
            />
            {getFormErrorMessage('inclImage')}
          </div>
          <div className='form-right-sec'>
            <label htmlFor="text">Include Text: </label><br />
            <input
              type="text"
              id="incl-text"
              name="inclText"
              value={formikINCL.values.inclText}
              onChange={(e) => {
                formikINCL.setFieldValue('inclText', e.target.value);
              }}
              className={classNames('w-full mb-3 p-inputtext', { 'p-invalid': isFormFieldInvalid('inclText') })}
              accept="image/jpeg, image/png, image/gif"
            />
            {getFormErrorMessage('inclText')}
          </div>
        </div>
        <div className="form-button">
          <Button label="Add Include Information" type="submit" loading={loading} className='p-button p-component p-button-label' />
        </div>
      </form>
    </PrimeReactProvider>
  )
}
