import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import Login from './Login';
import ErrorPage from './dashboardRoutes/error-page';
// import DashBoardPage from './dashboardRoutes/dashboard';
import Layout from './dashboardRoutes/Layout';
import PackagePage from './dashboardRoutes/packages/packages';
import PackageListPage from './dashboardRoutes/packages/packageList';
import AddPackageCategory from './dashboardRoutes/packages/addPackageCategory';
import AddPackageTag from './dashboardRoutes/packages/addPackageTag';
import PackageInformation from './dashboardRoutes/packages/packageInformation';
import PackageGallery from './dashboardRoutes/packages/packageGallery';
import HeaderInfo from './dashboardRoutes/pages/headerInfo';

import App from './App';
import { CommonVariableProvider } from './common';

import "primereact/resources/themes/lara-light-cyan/theme.css";
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";


const router = createBrowserRouter([{
  children: [
    {
      path: '/',
      element: <Login />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/dashboard',
      element: <Layout />,
      errorElement: <ErrorPage />, // Handle errors within the layout
      children: [
        {
          path: "/dashboard",
          element: <PackagePage />,
        },      
        {
          path: "/dashboard/:package_type/category",
          element: <AddPackageCategory />,        
        },
        {
          path: "/dashboard/:package_type/tags",
          element: <AddPackageTag />,        
        },
        {
          path: "/dashboard/:package_type/list",
          element: <PackageListPage />,        
        },
        {
          path: "/dashboard/:package_type/:package_list_slug/edit",
          element: <PackageInformation />,        
        },
        {
          path: "/dashboard/:package_type/:package_list_slug/gallery",
          element: <PackageGallery />,        
        },
        {
          path: "/dashboard/pages/:page_slug",
          element: <HeaderInfo />,        
        },
        // {
        //   path: "/dashboard/pages/footer-info",
        //   element: <PackageGallery />,        
        // },
        // {
        //   path: "/dashboard/pages/home-info",
        //   element: <PackageGallery />,        
        // },
      ],
    },
  ],
}]);



  

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <PrimeReactProvider>
    <CommonVariableProvider>
      <RouterProvider router={router} />
    </CommonVariableProvider>
  </PrimeReactProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
