import React, { useState, useRef } from "react";
import { PrimeReactProvider } from 'primereact/api';
import "primeflex/primeflex.css";
import { gbData } from './../../globalVarbs';
import PackageTypeList from './packageTypeList';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';

export default function PackagePage() {

    console.log(gbData.API_URL);

    const [visible, setVisible] = useState(false);
    const [position, setPosition] = useState('center');
    const [pckgtypName, setPckgtypName] = useState(null);
    const [pckgtypSlug, setPckgtypSlug] = useState(null);
    const [packageTypeList, setPackageTypeList] = useState([]);
    const [packageTypeListLoadingStatus, setPackageTypeListLoadingStatus] = useState( 'Loading List' );
    const [loading, setLoading] = useState(false);

    // toast and formik functions
        const toast = useRef(null);

        const showT = (severity = 'success', summary = 'failed', toastMessage = '') => {
            toast.current.show({ severity: severity, summary: summary, detail: toastMessage });
        };

        const isFormFieldInvalid = ( name ) => !!( formik.touched[ name ] && formik.errors[ name ] );

        const getFormErrorMessage = ( name ) => {
            return isFormFieldInvalid( name ) ? <small className="p-error">{ formik.errors[ name ] }</small> : <small className="p-error">&nbsp;</small>;
        };
    // end


    //  function to fetch package type list
        const fetchData = async () => {
            await fetch( gbData.API_URL + '/admin-get-package-type', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then( response => {
                if ( !response.ok ) {
                    showT( 'error', 'Failed', 'Network response was not ok' );
                    throw new Error( 'Network response was not ok' );
                }
                return response.json();
            })
            .then( ApiResponse => {
                // Process the newly created user data
                // console.log( 'ApiResponse:', ApiResponse );
                if ( ApiResponse.success == 0 ) {
                    showT( 'error', 'Failed', ApiResponse.message );
                    return;
                }

                if ( ApiResponse.data.length < 1 ) {
                    setPackageTypeListLoadingStatus( "No Package Type Found" );
                    setPackageTypeList( ApiResponse.data );
                } else {
                    setPackageTypeList( ApiResponse.data );
                }
            })
            .catch( error => {
                alert(error)
                console.error('Error:', error);
            });
        }
    // end

    // formik validation and form submit
        const formik = useFormik({
            initialValues: {
                pckgtypName: '',
                pckgtypSlug: ''
            },
            validate: ( data ) => {
                let errors = {};

                if ( !data.pckgtypName ) {
                    errors.pckgtypName = 'PackageName is required.';
                }

                if ( !data.pckgtypSlug ) {
                    errors.pckgtypSlug = 'PackageSlug is required.';
                }

                return errors;
            },
            onSubmit: ( data ) => {
                // data && showT(data);
                // console.log(data);
                // return;
                setLoading( true );

                fetch( gbData.API_URL + '/admin-create-package-type', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "pck_name": data.pckgtypName,
                        "pck_slug": data.pckgtypSlug
                    }),
                })
                .then( response => {
                    if ( !response.ok ) {
                        showT('error', 'Failed', 'Network response was not ok' );
                        throw new Error(  );
                        setLoading(false);
                    }
                    return response.json();
                })
                .then( ApiResponse => {
                    // Process the newly created user data
                    // console.log( 'ApiResponse:', ApiResponse );
                    if ( ApiResponse.success == 0 ) {
                        showT( 'error', 'Failed', ApiResponse.message );
                        setLoading( false );
                        return;
                    }

                    showT( 'success', 'Success', ApiResponse.message );
                    formik.resetForm();
                    // setVisible(false);
                    setLoading(false);
                    fetchData();
                })
                .catch(error => {
                    alert(error)
                });                
            }
        });
    // end

    

    const showD = (position) => {
        setPosition(position);
        setVisible(true);
    };

    const headerElement = (
        <div>
            <h1>Add Package Type</h1>
        </div>
    );

    return (
        <PrimeReactProvider>
            <Toast ref={toast} position="top-center" />
            <div className='db-pckg-type-wrapper'>
                <div className="pckg-type-button">
                    {/* <Button label="Add Package Type" icon="pi pi-external-link" onClick={() => showD('top')} /> */}
                </div>
                <Dialog header={headerElement} visible={visible} style={{ width: '30vw' }} onHide={() => setVisible(false)} position={position}>
                    <div className="m-0">
                        <form onSubmit={formik.handleSubmit} className="flex flex-column gap-2 add-pkg-pop-form">
                            <div className="login-field-wrapper">
                                <div className="login-field">
                                    <span className="p-float-labell">
                                        <InputText
                                            placeholder="PackageType - Name"
                                            name="pckgtypName"
                                            value={formik.values.pckgtypName}
                                            onChange={(e) => {
                                                formik.setFieldValue('pckgtypName', e.target.value);
                                            }}
                                            className={classNames('w-full mb-3', { 'p-invalid': isFormFieldInvalid('pckgtypName') })}
                                        />
                                        {/* <label htmlFor="input_adminUsername">Admin - Username</label> */}
                                        {getFormErrorMessage('pckgtypName')}
                                    </span>

                                </div>
                                <div className="login-field">
                                    <span className="p-float-labell">
                                        <InputText
                                            placeholder="PackageType - Slugg"
                                            name="pckgtypSlug"
                                            value={formik.values.pckgtypSlug}
                                            onChange={(e) => {
                                                formik.setFieldValue('pckgtypSlug', e.target.value.replace(/ /g, "_"));
                                            }}
                                            className={classNames('w-full mb-3', { 'p-invalid': isFormFieldInvalid('pckgtypSlug') })}
                                        />
                                        {/* <label htmlFor="input_adminPassword">Admin - Password</label> */}
                                        {getFormErrorMessage('pckgtypSlug')}
                                    </span>

                                </div>
                                <div className="login-field">
                                    <Button type="submit" loading={loading} label="Add Package type" />
                                </div>
                            </div>
                        </form>
                    </div>
                </Dialog>
                <PackageTypeList fetchData={fetchData} packageTypeList={packageTypeList} packageTypeListLoadingStatus={packageTypeListLoadingStatus} />
            </div>
        </PrimeReactProvider>
    );
}