import React, { useState, useRef, useEffect  } from "react"; 

import {gbData} from '../../globalVarbs';
import { PrimeReactProvider } from 'primereact/api';
import "primeflex/primeflex.css";

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Checkbox } from "primereact/checkbox";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';

import { useParams, useNavigate   } from 'react-router-dom';

export default function PackageList() {

    let { package_type } = useParams();
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [visibleUpd, setVisibleUpd] = useState(false);
    const [position, setPosition] = useState('center');
    const [loadingData, setLoadingData] = useState( false )
    const [packageListData, setPackageListData] = useState( [] )
    const [packageCategs, setPackageCategs] = useState( [] )
    const [packageTags, setPackageTags] = useState( [] )
    const [pcklstCategsUpd, setPcklstCategsUpd] = useState( [] )
    const [loading, setLoading] = useState(false);

    

    // const [checked, setChecked] = useState(false);
    // let packageListData = []

    // formik and toasts functions
        const toast = useRef(null);

        const showT = ( severity='success', summary='failed', toastMessage='') => {
            toast.current.show({ severity: severity, summary: summary, detail: toastMessage });
        };
        const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

        const getFormErrorMessage = (name) => {
            return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
        };

        const showD = (position) => {
            setPosition(position);
            setVisible(true);
        };
        const showDUpd = (position) => {
            setPosition(position);
            setVisibleUpd(true);
        };
    // end
    
    const fetchList = async () => {
        await fetch( gbData.API_URL+'/admin-get-package-type-list', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "pckg_type_slug":package_type
            }),
        })
        .then( response => {
            if( !response.ok ){
                throw new Error( 'Network response was not ok' );
            }
            return response.json();
        })
        .then( ApiResponse => {
            // Process the newly created user data
            console.log('ApiResponse:', ApiResponse);
            if( ApiResponse.success == 0 ){
                showT( 'error', 'Failed', ApiResponse.message);
                return;
            }
            
            if( ApiResponse.data.package_list.length > 0 ){
                setPackageListData( ApiResponse.data.package_list )
            }else{
                setPackageListData( [] )
            }
            if( ApiResponse.data.package_categs.length > 0 ){
                setPackageCategs( ApiResponse.data.package_categs )
            }else{
                setPackageCategs( [] )
            }
            if( ApiResponse.data.package_tags.length > 0 ){
                setPackageTags( ApiResponse.data.package_tags )
            }else{
                setPackageTags( [] )
            }
            
        })
        .catch(error => {
            alert(error)
            // console.error('Error:', error);
        });   

        setLoadingData(true);
    }    
  
    const formik = useFormik({
        initialValues: {
          pckgName: '',
          pckgSlug:'',
          pckgCateg:'',
          pckgTag:'',
          isVisible:false,
          pckgTypeSlug:package_type
        },
        validate: ( data ) => {
            let errors = {};
  
            if ( !data.pckgName ) {
                errors.pckgName = 'Package Name is required.';
            }
  
            if ( !data.pckgSlug ) {
                errors.pckgSlug = 'Package Slug is required.';
            }            
  
            return errors;
        },
        onSubmit: ( data ) => {
            // data && showT(data);
            // console.log(data);
            // return;
            setLoading( true );

            let pckage_categs_get = [];
            let pckage_tags_get = [];
            if( data.pckgCateg.length ){
                data.pckgCateg.map( ( valueCateg, indexCateg )=>{ 
                    pckage_categs_get.push( valueCateg.cat_id );
                } )
                
            }
            if( data.pckgTag.length ){
                data.pckgTag.map( ( valueTag, indexTag )=>{ 
                    pckage_tags_get.push( valueTag.tag_id );
                } )
                
            }
            // console.log(pckage_categs_get);;
            // console.log(pckage_tags_get);
  
            fetch( gbData.API_URL+'/admin-create-package-type-list', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "pckgName":data.pckgName,
                    "pckgSlug":data.pckgSlug,
                    "pckgCateg":pckage_categs_get,
                    "pckgTag":pckage_tags_get,
                    "packg_ls_isvisible":data.isVisible,
                    "pckgTypeSlug":data.pckgTypeSlug
                }),
            })
            .then( response => {
                if( !response.ok ){
                    showT( 'error', 'Failed', 'Network response was not ok' );
                    throw new Error( 'Network response was not ok' );
                    setLoading(false);
                }
                return response.json();
            })
            .then( ApiResponse => {
                // Process the newly created user data
                // console.log('ApiResponse:', ApiResponse);
                if( ApiResponse.success == 0 ){
                    showT( 'error', 'Failed', ApiResponse.message);
                    setLoading( false );
                    return;
                }
              //   setVisible(false);
                formik.resetForm();
                showT( 'success', 'Success', ApiResponse.message);              
                setLoading( false );
                fetchList();
            })
            .catch(error => {
                alert(error)
                // console.error('Error:', error);
            });
            // formik.resetForm();
        }
    });

    const formikUpd = useFormik({
        initialValues: {
          pckgNameUpd: '',
          pckgSlugUpd:'',
          pckgCategUpd:'',
          pckgTagUpd:'',
          isVisibleUpd:false,
          pckgTypeSlugUpd:package_type
        },
        validate: ( data ) => {
            let errorsUpd = {};
  
            if ( !data.pckgNameUpd ) {
                errorsUpd.pckgNameUpd = 'Package Name is required.';
            }
  
            if ( !data.pckgSlugUpd ) {
                errorsUpd.pckgSlugUpd = 'Package Slug is required.';
            }            
  
            return errorsUpd;
        },
        onSubmit: ( data ) => {
            // data && showT(data);
            // console.log(data);
            // return;
            setLoading( true );

            let pckage_categs_getUpd = [];
            let pckage_tags_getUpd = [];
            if( data.pckgCategUpd.length ){
                data.pckgCategUpd.map( ( valueCategUpd, indexCateg )=>{ 
                    pckage_categs_getUpd.push( valueCategUpd.cat_id );
                } )
                
            }
            if( data.pckgTagUpd.length ){
                data.pckgTagUpd.map( ( valueTagUpd, indexTag )=>{ 
                    pckage_tags_getUpd.push( valueTagUpd.tag_id );
                } )
                
            }
            // console.log(pckage_categs_getUpd);;
            // console.log(pckage_tags_getUpd);
  
            fetch( gbData.API_URL+'/admin-update-package-type-list', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "pckgId":data.pckgIdUpd,
                    "pckgName":data.pckgNameUpd,
                    "pckgSlug":data.pckgSlugUpd,
                    "pckgCateg":pckage_categs_getUpd,
                    "pckgTag":pckage_tags_getUpd,
                    "packg_ls_isvisible":data.isVisibleUpd,
                    "pckgTypeSlug":data.pckgTypeSlugUpd
                }),
            })
            .then( response => {
                if( !response.ok ){
                    showT( 'error', 'Failed', 'Network response was not ok' );
                    throw new Error( 'Network response was not ok' );
                    setLoading(false);
                }
                return response.json();
            })
            .then( ApiResponse => {
                // Process the newly created user data
                // console.log('ApiResponse:', ApiResponse);
                if( ApiResponse.success == 0 ){
                    showT( 'error', 'Failed', ApiResponse.message );
                    setLoading(false);
                    return;
                }
              //   setVisible(false);
                // formikUpd.resetForm();
                showT( 'success', 'Success', ApiResponse.message );              
                setLoading( false );
                fetchList();
            })
            .catch(error => {
                alert(error)
                // console.error('Error:', error);
            });
            // formik.resetForm();
        }
    });
    
  
    const headerElement  = (
        <div>
            <h1>Add Package List</h1>
        </div>
    );

    const listTableCategs = (rowData) => {
        let cats = "";
        rowData.packg_ls_categ.map( (e)=>{
            cats += e.cat_name+",";
        } )
        return cats;
    };

    const listTableTags = (rowData) => {
        let tags = "";
        rowData.packg_ls_tag.map( (e)=>{
            tags += e.tag_name+",";
        } )
        return tags;
    };
    // let navigate = useNavigate(); 
    const routeChange = ( rowData ) =>{ 
        console.log(rowData)
        let path = `/dashboard/`+package_type+`/`+rowData.packg_ls_slug+`/edit`; 
        navigate(path);
        // <Navigate to={path} replace={true} />
    }
    const routeChangeGallery = ( rowData ) =>{ 
        console.log(rowData)
        let path = `/dashboard/`+package_type+`/`+rowData.packg_ls_slug+`/gallery`; 
        navigate(path);
        // <Navigate to={path} replace={true} />
    }

    const deletePackageList = ( e,rowData ) => {
        // console.log(rowData);return;
        e.target.innerHTML = "Deleting...";
        fetch(gbData.API_URL + '/admin-delete-package-type-list', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "package_list_slug": rowData.packg_ls_slug,
                "package_slug": rowData.packg_type
            }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
                e.target.innerHTML = "Delete";
            }
            return response.json();
        })
        .then(ApiResponse => {
            // Process the newly created user data
            console.log('ApiResponse:', ApiResponse);
            if (ApiResponse.success == 0) {
                showT('error', 'Failed', ApiResponse.message);
                e.target.innerHTML = "Delete";
                return;
            }

            showT('success', 'Success', ApiResponse.message);

            fetchList();

        })
        .catch(error => {
            alert(error)
            e.target.innerHTML = "Delete";
        });
    }
   
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button label="Update Package" outlined className="mr-1" onClick={() => editProduct(rowData)} />
                <Button label="Edit Package" outlined className="mr-1" onClick={()=>routeChange(rowData)} />
                <Button label="Edit Gallery" outlined className="mr-1" onClick={()=>routeChangeGallery(rowData)} />
                <Button label="Delete" outlined className="mr-1" onClick={(e)=>deletePackageList(e,rowData)} />
            </React.Fragment>
        );
    };
    const editProduct = (List) => {
        // console.log(List)
        let isVisible = false;
        if( List.packg_ls_isvisible ){
            isVisible = true;
        }
        formikUpd.setFieldValue('pckgNameUpd',List.packg_ls_name);
        formikUpd.setFieldValue('pckgSlugUpd',List.packg_ls_slug);
        formikUpd.setFieldValue('pckgTypeSlugUpd',List.packg_type);
        formikUpd.setFieldValue('isVisibleUpd',isVisible);
        formikUpd.setFieldValue('pckgCategUpd',List.packg_ls_categ);
        formikUpd.setFieldValue('pckgTagUpd',List.packg_ls_tag);
        formikUpd.setFieldValue('pckgIdUpd',List.id);
        showDUpd('top')

    };
    

    useEffect(() =>{
        fetchList();
    },[])

  return (
    
    <PrimeReactProvider>
        {console.log("render")}
        { !loadingData ? 
            <p>loading</p>
            :
            <div className="">
                <Toast ref={toast} />
                <div className="pckg-type-button">
                <Button label="Add Package List" icon="pi pi-external-link" onClick={() => showD('top')}  />
                </div>
                <Dialog header={headerElement} visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)} position={position}>
                    <div className="m-0">
                    <form onSubmit={formik.handleSubmit} className="flex flex-column gap-2 add-pkg-pop-form formadd">
                        <div className="login-field-wrapper">
                            <div className="login-field">
                                <span className="p-float-labell">
                                    <InputText
                                        placeholder="Package - Name"
                                        name="pckgName"
                                        value={formik.values.pckgName}
                                        onChange={(e) => {
                                            formik.setFieldValue('pckgName', e.target.value);
                                        }}
                                        className={classNames( 'w-full mb-3', { 'p-invalid': isFormFieldInvalid('pckgName')})}
                                    /> 
                                    {/* <label htmlFor="input_adminUsername">Admin - Username</label> */}
                                    {getFormErrorMessage('pckgName')}  
                                </span>
                                
                            </div>  
                            <div className="login-field">
                                <span className="p-float-labell">
                                    <InputText
                                        placeholder="Package - Slug"
                                        name="pckgSlug"
                                        value={formik.values.pckgSlug}
                                        onChange={(e) => {
                                            formik.setFieldValue('pckgSlug', e.target.value.replace(/ /g, "_"));
                                        }}
                                        className={classNames( 'w-full mb-3', { 'p-invalid': isFormFieldInvalid('pckgSlug') })}
                                    /> 
                                    {/* <label htmlFor="input_adminPassword">Admin - Password</label> */}
                                    {getFormErrorMessage('pckgSlug')}  
                                </span>
                                
                            </div>
                            <div className="login-field">
                                <span className="p-float-labell">
                                <MultiSelect
                                    name="pckgCateg"
                                    options={packageCategs}
                                    value={formik.values.pckgCateg}
                                    onChange={(e) => {
                                        formik.setFieldValue('pckgCateg', e.value);
                                    }}
                                    optionLabel="cat_name"
                                    placeholder="Select Categories"
                                    maxSelectedLabels={3}
                                    className="w-full"
                                />
                                {getFormErrorMessage('pckgCateg')}
                                </span>
                                
                            </div>     
                            <div className="login-field">
                                <span className="p-float-labell">
                                <MultiSelect
                                    name="pckgTag"
                                    options={packageTags}
                                    value={formik.values.pckgTag}
                                    onChange={(e) => {
                                        formik.setFieldValue('pckgTag', e.value);
                                    }}
                                    optionLabel="tag_name"
                                    placeholder="Select Tags"
                                    maxSelectedLabels={3}
                                    className="w-full"
                                />
                                {getFormErrorMessage('pckgTag')}
                                </span>
                                
                            </div>    
                            <div className="login-field">
                                <span className="p-float-labell">
                                {/* <Checkbox name="isVisible" onChange={e => setChecked(e.checked)} checked={checked}></Checkbox> */}
                                <Checkbox
                                    name="isVisible"
                                    checked={formik.values.isVisible}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('isVisible') })}
                                    onChange={(e) => {
                                        formik.setFieldValue('isVisible', e.checked);
                                    }}
                                ></Checkbox>
                                <label htmlFor="ingredient1" className="ml-2">Visible</label>
                                </span>
                                
                            </div>    


                            
                            <div className="login-field">
                                <span className="p-float-labell">
                                    <InputText
                                        placeholder="PackageType - Slug"
                                        name="pckgTypeSlug"
                                        value={formik.values.pckgTypeSlug}
                                        className={classNames( 'w-full mb-3', { 'p-invalid': isFormFieldInvalid('pckgTypeSlug') })}
                                    /> 
                                    {/* <label htmlFor="input_adminPassword">Admin - Password</label> */}
                                    {getFormErrorMessage('pckgTypeSlug')}  
                                </span>
                                
                            </div>
                            <div className="login-field">
                                <Button type="submit" loading={loading} label="Add Package List" />
                            </div>                
                        </div>            
                    </form>
                    </div>
                </Dialog>

                <Dialog header={headerElement} visible={visibleUpd} style={{ width: '4 0vw' }} onHide={() => setVisibleUpd(false)} position={position}>
                    <div className="m-0">
                    <form onSubmit={formikUpd.handleSubmit} className="flex flex-column gap-2 add-pkg-pop-form formupdate">
                        <div className="login-field-wrapper">
                            <div className="login-field">
                                <span className="p-float-labell">
                                    <InputText
                                        placeholder="Package - Name"
                                        name="pckgNameUpd"
                                        value={formikUpd.values.pckgNameUpd}
                                        onChange={(e) => {
                                            formikUpd.setFieldValue('pckgNameUpd', e.target.value);
                                        }}
                                        className={classNames( 'w-full mb-3', { 'p-invalid': isFormFieldInvalid('pckgNameUpd')})}
                                    /> 
                                    {/* <label htmlFor="input_adminUsername">Admin - Username</label> */}
                                    {getFormErrorMessage('pckgNameUpd')}  
                                </span>
                                
                            </div>  
                            <div className="login-field">
                                <span className="p-float-labell">
                                    <InputText
                                        placeholder="Package - Slug"
                                        name="pckgSlugUpd"
                                        value={formikUpd.values.pckgSlugUpd}
                                        onChange={(e) => {
                                            formikUpd.setFieldValue('pckgSlugUpd', e.target.value.replace(/ /g, "_"));
                                        }}
                                        className={classNames( 'w-full mb-3', { 'p-invalid': isFormFieldInvalid('pckgSlugUpd') })}
                                    /> 
                                    {/* <label htmlFor="input_adminPassword">Admin - Password</label> */}
                                    {getFormErrorMessage('pckgSlugUpd')}  
                                </span>
                                
                            </div>
                            <div className="login-field">
                                <span className="p-float-labell">
                                <MultiSelect
                                    name="pckgCategUpd"
                                    options={packageCategs}
                                    value={formikUpd.values.pckgCategUpd}
                                    onChange={(e) => {
                                        formikUpd.setFieldValue('pckgCategUpd', e.value);
                                    }}
                                    optionLabel="cat_name"
                                    placeholder="Select Categories"
                                    maxSelectedLabels={3}
                                    className="w-full"
                                />
                                {getFormErrorMessage('pckgCategUpd')}
                                </span>
                                
                            </div>     
                            <div className="login-field">
                                <span className="p-float-labell">
                                <MultiSelect
                                    name="pckgTagUpd"
                                    options={packageTags}
                                    value={formikUpd.values.pckgTagUpd}
                                    onChange={(e) => {
                                        formikUpd.setFieldValue('pckgTagUpd', e.value);
                                    }}
                                    optionLabel="tag_name"
                                    placeholder="Select Tags"
                                    maxSelectedLabels={3}
                                    className="w-full"
                                />
                                {getFormErrorMessage('pckgTagUpd')}
                                </span>
                                
                            </div>    
                            <div className="login-field">
                                <span className="p-float-labell">
                                {/* <Checkbox name="isVisible" onChange={e => setChecked(e.checked)} checked={checked}></Checkbox> */}
                                <Checkbox
                                    name="isVisibleUpd"
                                    checked={formikUpd.values.isVisibleUpd}
                                    value={formikUpd.values.isVisibleUpd}
                                    className={classNames({ 'p-invalid': isFormFieldInvalid('isVisibleUpd') })}
                                    onChange={(e) => {
                                        console.log(e.checked)
                                        formikUpd.setFieldValue('isVisibleUpd', e.checked);
                                    }}
                                ></Checkbox>
                                <label htmlFor="ingredient1" className="ml-2">Visible</label>
                                </span>
                                
                            </div>    


                            
                            <div className="login-field">
                                <span className="p-float-labell">
                                    <InputText
                                        placeholder="PackageType - Slug"
                                        name="pckgTypeSlugUpd"
                                        value={formikUpd.values.pckgTypeSlugUpd}
                                        className={classNames( 'w-full mb-3 pkgtextbox', { 'p-invalid': isFormFieldInvalid('pckgTypeSlugUpd') })}
                                    />
                                    <InputText
                                        placeholder="PackageType - Slug"
                                        name="pckgIdUpd"
                                        value={formikUpd.values.pckgIdUpd}
                                        className={classNames( 'w-full mb-3', { 'p-invalid': isFormFieldInvalid('pckgIdUpd') })}
                                    /> 
                                    {/* <label htmlFor="input_adminPassword">Admin - Password</label> */}
                                    {/* {getFormErrorMessage('pckgTypeSlugUpd')}   */}
                                </span>
                                
                            </div>
                            <div className="login-field">
                                <Button type="submit" loading={loading} label="Update Package" />
                            </div>                
                        </div>            
                    </form>
                    </div>
                </Dialog>

                <div className="card">
                    <DataTable dataKey="id" value={packageListData}  tableStyle={{ minWidth: '50rem' }}>
                    {/* <Column field="tag_name" header="Tag Name"></Column> */}
                        <Column field="packg_ls_name" header="Package Name"></Column>
                        <Column field="packg_ls_slug" header="Package Slug"></Column>                    
                        <Column field="packg_ls_categ" header="Package Categories" body={listTableCategs}></Column>                    
                        <Column field="packg_ls_categ" header="Package Tags" body={listTableTags}></Column>                    
                        {/* <Column field="packg_ls_tag" header="Package tags"></Column>                     */}
                        <Column field="packg_ls_isvisible" header="Is Visible"></Column>   
                        <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }} className="button-action-cls"></Column>      
                                      </DataTable>
                </div>
            </div>
        }
        
    </PrimeReactProvider>
    
  );
}