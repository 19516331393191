import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { gbData } from '../../globalVarbs';
import { PrimeReactProvider } from 'primereact/api';
import "primeflex/primeflex.css";
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';

export default function PackageListReview(props) {

    let { package_type } = useParams();
    let { package_list_slug } = useParams();
    const imageRef = useRef();
    const [listreview, setListreview] = useState("");
    const [includeImage, setIncludeImage] = useState("");
    const [includeText, setIncludeText] = useState("");
    const [rvImageCst, setRvImageCst] = useState("");
    const [loading, setLoading] = useState(false);

    // formik and toast functions
        const ratings = [
            { name: 'One', code: '1' },
            { name: 'Two', code: '2' },
            { name: 'Three', code: '3' },
            { name: 'Four', code: '4' },
            { name: 'Five', code: '5' }
        ];

        const [formData, setFormData] = useState({
            image: null,
            text: '',
        });

        const handleChange = (event) => {
            const { name, value, files } = event.target;
            setFormData((prevData) => ({
                ...prevData,
                [name]: name === 'rvImage' ? files[0] : value,
            }));
        };


        const toast = useRef(null);
        const showT = (severity = 'success', summary = 'failed', toastMessage = '') => {
            toast.current.show({ severity: severity, summary: summary, detail: toastMessage });
        };
        const isFormFieldInvalid = (name) => !!(formikRV.touched[name] && formikRV.errors[name]);

        const getFormErrorMessage = (name) => {
            return isFormFieldInvalid(name) ? <small className="p-error">{formikRV.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
        };
    // end 

    // function to fetch reviews
        const fetchPckgRV = async () => {
            try {
                const response = await fetch( gbData.API_URL + '/admin-get-package-list-review', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "pckg_list_slug": package_list_slug
                    }),
                });

                if ( !response.ok ) {
                    showT('error', 'Failed', `API request failed with status ${response.status}` );
                    throw new Error( `API request failed with status ${response.status}` );
                }

                const respData = await response.json();
                // console.log('API response:', respData);
                setListreview(respData.data)
                // Handle successful API response here
            } catch ( error ) {
                alert(error)
                // console.error('Error:', error);
                // Handle errors here
            } finally {
                // Optionally, clear the form after submission
                //   setFormData([{ id: 1, image: null, text: '' }]);
            }
        }
    // end

    // function to submit a review
        const formikRV = useFormik({
            initialValues: {
                rvStars: '',
                rvTitle: '',
                rvDetail: '',
                rvSort: '',
                rvImage: ''
            },
            validate: ( data ) => {
                let errorsUpd = {};
                console.log(data);

                if ( !data.rvImage ) {
                    errorsUpd.rvImage = 'Review Image is required.';
                }

                if ( !data.rvStars ) {
                    errorsUpd.rvStars = 'Review Stars is required.';
                }

                if ( !data.rvTitle ) {
                    errorsUpd.rvTitle = 'Review Title is required.';
                }

                if ( !data.rvDetail ) {
                    errorsUpd.rvDetail = 'Review Details is required.';
                }

                if ( !data.rvSort ) {
                    errorsUpd.rvSort = 'Review Sort is required.';
                }

                return errorsUpd;
            },
            onSubmit: ( data ) => {
                // data && showT(data);
                // console.log(data.rvStars);return;
                setLoading( true );
                const formDataToSend = new FormData();
                formDataToSend.append( 'list_slug', package_list_slug );
                formDataToSend.append( 'list_packg_type', package_type) ;
                formDataToSend.append( 'review_image', rvImageCst );
                formDataToSend.append( 'review_title', data.rvTitle );
                formDataToSend.append( 'review_text', data.rvDetail );
                formDataToSend.append( 'review_star', data.rvStars.code );
                formDataToSend.append( 'review_sort', data.rvSort );
                console.log( formDataToSend );

                fetch( gbData.API_URL + '/admin-create-package-list-review', {
                    method: 'POST',
                    body: formDataToSend
                })
                .then( response => {
                    if (!response.ok) {
                        showT( 'error', 'Failed', 'Network response was not ok' );
                        throw new Error( 'Network response was not ok' );
                        setLoading(false);
                    }
                    return response.json();
                })
                .then( ApiResponse => {
                    // Process the newly created user data
                    // console.log( 'ApiResponse:', ApiResponse );
                    if ( ApiResponse.success == 0 ) {
                        showT( 'error', 'Failed', ApiResponse.message );
                        setLoading( false );
                        return;
                    }
                    //   setVisible(false);
                    formikRV.resetForm();
                    showT( 'success', 'Success', ApiResponse.message );
                    setLoading( false );
                    fetchPckgRV();
                })
                .catch(error => {
                    alert(error)
                    // console.error('Error:', error);
                    setLoading( false );
                });
            }
        });
    // end

    // function to delete a review
        const deleteTPInfor = async ( e,rowData ) => {
            // console.group( rowData );
            e.target.innerHTML = "Deleting...";

            const formDataToSend = new FormData();
            formDataToSend.append( 'id', rowData.id );

            try {
                const response = await fetch( gbData.API_URL + '/admin-delete-package-list-review', {
                    method: 'POST',
                    body: formDataToSend,
                });

                if ( !response.ok ) {
                    showT( 'error', 'Failed', `API request failed with status ${response.status}` );
                    throw new Error( `API request failed with status ${response.status}` );
                    e.target.innerHTML = "Delete";
                }

                const data = await response.json();
                // console.log( 'API response:', data );
                showT( 'success', 'Success', data.message );
                fetchPckgRV();
                // Handle successful API response here
            } catch ( error ) {
                alert(error)
                // console.error('Error:', error);
                e.target.innerHTML = "Delete";
                // Handle errors here
            } finally {
                // Optionally, clear the form after submission
                setFormData( { image: '', text: '' } );
            }
        }
    // end

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button label="Delete" outlined className="mr-1" onClick={(e) => deleteTPInfor(e,rowData)} />
            </React.Fragment>
        );
    };
    const onCellEditComplete = (e) => {
        let { rowData, newValue, field, originalEvent: event } = e;
    };

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };
    const onRowEditComplete = async (e) => {
        console.log(e)
        const formDataToSend = new FormData();
        formDataToSend.append('sort_for', 'review');
        formDataToSend.append('id', e.newRowData.id);
        formDataToSend.append('review_sort', e.newValue);

        try {
            const response = await fetch( gbData.API_URL + '/admin-package-sort-update', {
                method: 'POST',
                body: formDataToSend,
            });

            if (!response.ok) {
                throw new Error(`API request failed with status ${response.status}`);
            }

            const data = await response.json();
            console.log( 'API response:', data );
            showT( 'success', 'Success', data.message );
            fetchPckgRV();
            // Handle successful API response here
        } catch (error) {
            alert(error)
            // Handle errors here
        } finally {
            // Optionally, clear the form after submission
            // setFormData({ image: '', text: '' });/
        }
    };

    useEffect(() => {
        fetchPckgRV();
    }, [])

    return (

        <PrimeReactProvider>
            <Toast ref={toast} />
            <DataTable value={listreview} tableStyle={{ minWidth: '50rem' }}  >
                <Column field={props.showImageTemplate} header="RV Image"></Column>
                <Column field="review_title" header="RN Title"></Column>
                <Column field="review_text" header="RV Review"></Column>
                <Column field="review_star" header="RV Star"></Column>
                <Column key='id' field="review_sort" header="RV Sort" editor={(options) => textEditor(options)} onCellEditComplete={onRowEditComplete}></Column>
                <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
            </DataTable>
            <form onSubmit={formikRV.handleSubmit} className='edit-form-forth mt-40 mb-40'>
                <div className="form-field-group">
                    <span className="p-float-labell1">
                        <label htmlFor="image">Review Image: </label><br />
                        <input
                            type="file"
                            id="rv-Image"
                            name="rvImage"
                            value={formikRV.values.rvImage}
                            onChange={(e) => {
                                formikRV.setFieldValue('rvImage', e.target.value);
                                setRvImageCst(e.currentTarget.files[0])
                            }}
                            className={classNames('w-full mb-3 p-inputtext p-component', { 'p-invalid': isFormFieldInvalid('rvImage') })}
                            accept="image/jpeg, image/png, image/gif"
                        />
                        {getFormErrorMessage('rvImage')}
                    </span>
                    <span className="p-float-labell1">
                    <label htmlFor="image">Review Stars: </label><br/>
                    <Dropdown
                        id="rv-Stars"
                        name="rvStars"
                        value={formikRV.values.rvStars}
                        options={ratings}
                        optionLabel="name"
                        placeholder="Select Rating's"
                        onChange={(e) => {
                            formikRV.setFieldValue('rvStars', e.value);
                        }}
                        className={classNames('w-full mb-3 p-inputtext p-component', { 'p-invalid': isFormFieldInvalid('rvStars') })}
                    />
                    {getFormErrorMessage('rvStars')}
                </span>
                </div>
                <div className="form-field-group">
                <span className="p-float-labell1">
                    <label htmlFor="image">RV Title: </label><br/>
                    <input
                        type="text"
                        id="rvTitle"
                        name="rvTitle"
                        value={formikRV.values.rvTitle}
                        onChange={(e) => {
                            formikRV.setFieldValue('rvTitle', e.target.value);
                        }}
                        className={classNames('w-full mb-3 p-inputtext p-component', { 'p-invalid': isFormFieldInvalid('rvTitle') })}
                    />
                    {getFormErrorMessage('rvTitle')}
                </span>
                <span className="p-float-labell1">
                    <label htmlFor="text">RV Detail: </label><br/>
                    <textarea
                        id="rvDetail"
                        name="rvDetail"
                        value={formikRV.values.rvDetail}
                        onChange={(e) => {
                            formikRV.setFieldValue('rvDetail', e.target.value);
                        }}
                        className={classNames('w-full mb-3 p-inputtext p-component', { 'p-invalid': isFormFieldInvalid('rvDetail') })}
                    />
                    {getFormErrorMessage('rvDetail')}
                </span>
                </div>
                <div className="form-field-group">
                <span className="p-float-labell1">
                    <label htmlFor="text">RV Sort: </label><br/>
                    <InputNumber
                        id="rvSort"
                        name="rvSort"
                        value={formikRV.values.rvSort}
                        onChange={(e) => {
                            formikRV.setFieldValue('rvSort', e.value);
                        }}
                        className={classNames('w-full mb-3 p-inputtext p-component', { 'p-invalid': isFormFieldInvalid('rvSort') })}
                    />
                    {getFormErrorMessage('rvSort')}
                </span>     
                </div>
                <div className="form-button">
                    <Button label="Add Package Review" loading={loading} type="submit" className='p-button p-component p-button-label' />
                </div>
            </form>
        </PrimeReactProvider>
    )
}
