import { Sidebar } from 'primereact/sidebar';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import "primeflex/primeflex.css";
import { Outlet, Link,redirectDocument } from "react-router-dom";
import { PanelMenu } from 'primereact/panelmenu';
import logo from './images/Logo.png';
import Admin_Headers from './Admin_header';
import React, { useState, useRef, useContext, useEffect } from "react";  
import { CommonVariableContext } from '../common';

export default function DashBoardPage() {

    useEffect(() => {
        document.title = "RHAdventures Management"
    }, [])

    
    const { commonVariable, setCommonVariable } = useContext(CommonVariableContext);

    const redirect_login = ()=>{
        // setCommonVariable( false )
        window.location.href = "/";
    }    

    const packageListLink = (item, options) => (
        <Link to={'/dashboard/packages/list'}>Packages List</Link>
    );
    const packageAddCategs = (item, options) => (
        <Link to={'/dashboard/packages/category'}>Add Package Category</Link>
    );
    const packageAddPackageTag = (item, options) => (
        <Link to={'/dashboard/packages/tags'}>Add Package Tag</Link>
    );
    const pagesHeaderInfo = (item, options) => (
        <Link to={'/dashboard/pages/header-info'}>Header Information</Link>
    );
    const pagesFooterInfo = (item, options) => (
        <Link to={'/dashboard/pages/footer-info'}>Footer information</Link>
    );
    const pagesHomeInfo = (item, options) => (
        <Link to={'/dashboard/pages/home-info'}>Home Information</Link>
    );

    const items = [     
        {
            label: 'Packages',
            icon: 'pi pi-file',
            items: [
                {
                    label: 'Packages List',
                    icon: 'pi pi-file',
                    template: packageListLink
                },
                {
                    label: 'Add Package Category',
                    icon: 'pi pi-file',
                    template: packageAddCategs
                },
                {
                    label: 'Add Package Tag',
                    icon: 'pi pi-file',
                    template: packageAddPackageTag
                }
            ]
        },
        {
            label: 'Pages Information',
            icon: 'pi pi-file',
            items: [
                {
                    label: 'Header Information',
                    icon: 'pi pi-file',
                    template: pagesHeaderInfo  
                },
                // {
                //     label: 'Footer Information',
                //     icon: 'pi pi-file',
                //     template: pagesFooterInfo
                // },
                // {
                //     label: 'Home Information',
                //     icon: 'pi pi-file',
                //     template: pagesHomeInfo
                // }
            ]
        },
        {
            label: 'Sign Out',
            icon: 'pi pi-sign-out',
            command: () => {
                redirect_login()
            }
        }
    ];

  return (
    <PrimeReactProvider>
        { 
            ( !commonVariable || commonVariable==null ) ? 
            redirect_login() : 
    
            <div className='dashboard-container'>
                <div className='dashboard-wrapper'>
                    <div className='dashboard-sidebar-menu-wrapper'>
                        <div className='db-sidebar-menu'>
                            <div className='db-sdb-mn-ttl'>
                                <div className='db-dashboard-logo'>
                                    <Link to={'/dashboard/'}><img src={logo} alt="logo"/></Link>
                                </div>
                                <h3>DashBoard</h3>
                            </div>
                            <div className='db-sdbr-mns'>
                            <PanelMenu model={items} className="bswn-dashboard-sidebar-menu" />
                            </div>
                        </div>
                    </div>
                    <div className='dashboard-sidebar-content-wrapper'>
                            <Admin_Headers redirect_login={redirect_login }/>
                        <div className='db-content-container'>
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        }
    </PrimeReactProvider>
  );
}