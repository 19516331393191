import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { gbData } from '../../globalVarbs';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';

import "primeflex/primeflex.css";
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';


import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import backbutton from '../images/back-button.png';

export default function HeaderInfo() {

    const [loading, setLoading] = useState(false);
    let { page_slug } = useParams();

    const toast = useRef(null);
    const showT = (severity = 'success', summary = 'failed', toastMessage = '') => {
        toast.current.show({ severity: severity, summary: summary, detail: toastMessage });
    };

    const fetchheaderPageInfor = async () => {
        try {
            const response = await fetch(gbData.API_URL + '/admin-get-page-header-footer-information', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "page_info_slug": page_slug
                }),
            });

            if (!response.ok) {
                throw new Error(`API request failed with status ${response.status}`);
            }

            const respData = await response.json();
            console.log('API response:', respData);
            if( respData.data.isEmpty ){
                formikPageHeaderInfo.setFieldValue('pgHeaderEmail', respData.data.information.pgHeaderEmail);
                formikPageHeaderInfo.setFieldValue('pgHeaderPhone', respData.data.information.pgHeaderPhone);
                formikPageHeaderInfo.setFieldValue('pgHeaderFB', respData.data.information.pgHeaderFB);
                formikPageHeaderInfo.setFieldValue('pgHeaderInsta', respData.data.information.pgHeaderInsta);
                formikPageHeaderInfo.setFieldValue('pgHeaderYT', respData.data.information.pgHeaderYT);
                formikPageHeaderInfo.setFieldValue('pgFooterAddress', respData.data.information.pgFooterAddress);
                formikPageHeaderInfo.setFieldValue('pgFooterWP', respData.data.information.pgFooterWP);
                formikPageHeaderInfo.setFieldValue('mapLinkNF', respData.data.information.mapLinkNF);
                formikPageHeaderInfo.setFieldValue('pgFooterDC', respData.data.information.pgFooterDC);
                formikPageHeaderInfo.setFieldValue('pgFooterHD', respData.data.information.pgFooterHD);
                formikPageHeaderInfo.setFieldValue('mbForPckgCard', respData.data.information.mbForPckgCard);
            }
            // Handle successful API response here
        } catch (error) {
            console.error('Error:', error);
            // Handle errors here
        } finally {
            // Optionally, clear the form after submission
            //   setFormData([{ id: 1, image: null, text: '' }]);
        }
    }

    const formikPageHeaderInfo = useFormik({
        initialValues: {
            pgHeaderEmail: '',
            pgHeaderPhone: '',
            pgHeaderFB: '',
            pgHeaderInsta: '',
            pgHeaderYT: '',
            pgFooterAddress: '',
            pgFooterWP: '',
            mapLinkNF: '',
            pgFooterDC:''
        },
        onSubmit: async (data) => {
            console.log(data);
            setLoading(true);
            let page_json_inofr = JSON.stringify({
                "pgHeaderEmail": data.pgHeaderEmail,
                "pgHeaderPhone": data.pgHeaderPhone,
                "pgHeaderFB": data.pgHeaderFB,
                "pgHeaderInsta": data.pgHeaderInsta,
                "pgHeaderYT": data.pgHeaderYT,
                "pgFooterAddress": data.pgFooterAddress,
                "pgFooterWP":data.pgFooterWP,
                "mapLinkNF":data.mapLinkNF,
                "pgFooterDC":data.pgFooterDC,
                "pgFooterHD":data.pgFooterHD,
                "mbForPckgCard":data.mbForPckgCard,
            })

            try {
                const response = await fetch(gbData.API_URL + '/admin-create-update-page-header-footer-information', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "page_json_infor": page_json_inofr,
                        "page_info_slug":page_slug
                    }),
                });

                if (!response.ok) {
                    throw new Error(`API request failed with status ${response.status}`);
                    setLoading(false);
                }

                const data = await response.json();
                console.log('API response:', data);
                showT('success', 'Success', data.message);
                setLoading(false);
                fetchheaderPageInfor()
                // Handle successful API response here
            } catch (error) {
                setLoading(false);
                console.error('Error:', error);
                // Handle errors here
            } finally {
                // Optionally, clear the form after submission
                //   setFormData([{ id: 1, image: null, text: '' }]);
            }
        }
    })

    useEffect(() => {
        fetchheaderPageInfor();
    }, [])

    return(
        <PrimeReactProvider>
            <Toast ref={toast} />
             <form onSubmit={formikPageHeaderInfo.handleSubmit} className="edit-form-first mb-40">
                <div className="form-field-group">
                    <span className="p-float-labell">
                        <label>Header email</label><br />
                        <InputText name="pgHeaderEmail" value={formikPageHeaderInfo.values.pgHeaderEmail} onChange={(e) => {
                            formikPageHeaderInfo.setFieldValue('pgHeaderEmail', e.target.value);
                        }} />
                    </span>
                    <span className="p-float-labell">
                        <label>Header Phone</label><br />
                        <InputText name="pgHeaderPhone" value={formikPageHeaderInfo.values.pgHeaderPhone} onChange={(e) => {
                            formikPageHeaderInfo.setFieldValue('pgHeaderPhone', e.target.value);
                        }} />
                    </span>
                </div>
                <div className="form-field-group">
                    <span className="p-float-labell">
                        <label>Header Fb Link</label><br />
                        <InputText name="pgHeaderFB" value={formikPageHeaderInfo.values.pgHeaderFB} onChange={(e) => {
                            formikPageHeaderInfo.setFieldValue('pgHeaderFB', e.target.value);
                        }} />
                    </span>
                    <span className="p-float-labell">
                        <label>Header Insta Link</label><br />
                        <InputText name="pgHeaderInsta" value={formikPageHeaderInfo.values.pgHeaderInsta} onChange={(e) => {
                            formikPageHeaderInfo.setFieldValue('pgHeaderInsta', e.target.value);
                        }} />
                    </span>
                </div>
                <div className="form-field-group">
                <span className="p-float-labell">
                    <label>Header Youtube Link</label><br />
                    <InputText name="pgHeaderYT" value={formikPageHeaderInfo.values.pgHeaderYT} onChange={(e) => {
                        formikPageHeaderInfo.setFieldValue('pgHeaderYT', e.target.value);
                    }} />
                </span>
                <span className="p-float-labell">
                    <label>Footer Adress</label><br />
                    <InputText name="pgFooterAddress" value={formikPageHeaderInfo.values.pgFooterAddress} onChange={(e) => {
                        formikPageHeaderInfo.setFieldValue('pgFooterAddress', e.target.value);
                    }} />
                </span>
                </div>
                <div className="form-field-group">
                <span className="p-float-labell">
                    <label>Footer WhatsApp</label><br />
                    <InputText name="pgFooterWP" value={formikPageHeaderInfo.values.pgFooterWP} onChange={(e) => {
                        formikPageHeaderInfo.setFieldValue('pgFooterWP', e.target.value);
                    }} />
                </span>
                <span className="p-float-labell">
                    <label>Footer Description</label><br />
                    <InputTextarea name="pgFooterDC" value={formikPageHeaderInfo.values.pgFooterDC} onChange={(e) => {
                        formikPageHeaderInfo.setFieldValue('pgFooterDC', e.target.value);
                    }} />
                </span>
                </div>
                <div className="form-field-group">
                <span className="p-float-labell">
                    <label>Footer Heading</label><br />
                    <InputTextarea name="pgFooterHD" value={formikPageHeaderInfo.values.pgFooterHD} onChange={(e) => {
                        formikPageHeaderInfo.setFieldValue('pgFooterHD', e.target.value);
                    }} />
                </span>
                </div>
                <div className="form-field-group">
                <span className="p-float-labell">
                    <label>Mobile Number for Packages Card</label><br />
                    <InputTextarea name="mbForPckgCard" value={formikPageHeaderInfo.values.mbForPckgCard} onChange={(e) => {
                        formikPageHeaderInfo.setFieldValue('mbForPckgCard', e.target.value);
                    }} />
                </span>
                </div>
                <div className="form-button">
                    <Button type="submit" loading={loading} label="Update Package Information" />
                </div>
            </form>
        </PrimeReactProvider>
    )
}