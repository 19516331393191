import React, { useState, useRef, useEffect } from "react";
import { useParams, Link } from 'react-router-dom';

import PackageInformationIncluded from "./packageInformationIncluded"
import PackageInformationTourPlan from "./packageInformationTourPlan"
import PackageListReview from "./packageListReview"

import { gbData } from '../../globalVarbs';
import { PrimeReactProvider } from 'primereact/api';
import "primeflex/primeflex.css";

import { InputText } from "primereact/inputtext";
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';

import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import backbutton from '../images/back-button.png';

export default function PackageInformation() {

    let { package_type } = useParams();
    let { package_list_slug } = useParams();
    const [dayNight, setDayNight] = useState('');
    const [persons, setPersons] = useState('');
    const [location, setLocation] = useState('');
    const [overview, setOverview] = useState('');
    const [loading, setLoading] = useState(false);

    const toast = useRef(null);

    // const handleNormalFields = (e)=>{
    //     e.preventDefault();
    //     console.log(e.target)
    // }

    const fetchPckgInfor = async () => {
        try {
            const response = await fetch( gbData.API_URL + '/admin-get-package-information', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "pckg_list_slug": package_list_slug
                }),
            });

            if ( !response.ok ) {
                showT('error', 'Failed', `API request failed with status ${response.status}` );
                throw new Error( `API request failed with status ${response.status}` );
            }

            const respData = await response.json();
            // console.log('API response:', respData);
            if( respData.data.isEmpty ){
                formikUpdNF.setFieldValue( 'dayNightNF', respData.data.information.dayNightNF );
                formikUpdNF.setFieldValue( 'personsNF', respData.data.information.personsNF );
                formikUpdNF.setFieldValue( 'locationNF', respData.data.information.locationNF );
                formikUpdNF.setFieldValue( 'overviewNF', respData.data.information.overviewNF );
                formikUpdNF.setFieldValue( 'pricetNF', respData.data.information.pricetNF );
                formikUpdNF.setFieldValue( 'mapLinkNF', respData.data.information.mapLinkNF );
                formikUpdNF.setFieldValue( 'discountedPricetNF', respData.data.information.discountedPricetNF );
                formikUpdNF.setFieldValue( 'pckgContact', respData.data.information.pckgContact );
            }
            // Handle successful API response here
        } catch ( error ) {
            alert(error)
            // console.error('Error:', error);
            // Handle errors here
        } finally {
            // Optionally, clear the form after submission
            //   setFormData([{ id: 1, image: null, text: '' }]);
        }
    }

    const formikUpdNF = useFormik({
        initialValues: {
            dayNightNF: '',
            personsNF: '',
            locationNF: '',
            overviewNF: '',
            pricetNF: '',
            mapLinkNF: '',
            discountedPricetNF:'',
            pckgContact:""
        },
        onSubmit: async ( data ) => {
            // console.log(data);
            setLoading(true);
            let json_inor = JSON.stringify({
                "dayNightNF": data.dayNightNF,
                "personsNF": data.personsNF,
                "locationNF": data.locationNF,
                "overviewNF": data.overviewNF,
                "pricetNF": data.pricetNF,
                "mapLinkNF": data.mapLinkNF,
                "discountedPricetNF": data.discountedPricetNF,
                "pckgContact":data.pckgContact,
            })
            try {
                const response = await fetch( gbData.API_URL + '/admin-create-package-information', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "pckgLstSlug": package_list_slug,
                        "pckgLstinfo": json_inor,
                        "pckgLstPckgType": package_type
                    }),
                });

                if ( !response.ok ) {
                    showT( 'error', 'Failed', `API request failed with status ${response.status}` );
                    throw new Error( `API request failed with status ${response.status}` );
                    setLoading(false);
                }

                const data = await response.json();
                // console.log('API response:', data);

                if ( data.success == 0 ) {
                    showT( 'error', 'Failed', data.message );
                    setLoading(false);
                    return;
                }

                showT( 'success', 'Success', data.message );
                setLoading(false);
                fetchPckgInfor()
                // Handle successful API response here
            } catch (error) {
                alert(error)
                setLoading( false );
                // console.error( 'Error:', error );
                // Handle errors here
            } finally {
                // Optionally, clear the form after submission
                //   setFormData([{ id: 1, image: null, text: '' }]);
            }
        }
    })

    const showT = ( severity = 'success', summary = 'failed', toastMessage = '' ) => {
        toast.current.show( { severity: severity, summary: summary, detail: toastMessage } );
    };

    const showImageTemplate = ( rowData ) => {
        var $image_path;
        if ( rowData.includeImage ) {
            $image_path = gbData.LV_URL + rowData.includeImage;
        } else if ( rowData.review_image ) {
            $image_path = gbData.LV_URL + rowData.review_image;
        }
        return (
            <React.Fragment>
                <Image src={$image_path} alt="Image" width="40" height="40" preview />
            </React.Fragment>
        );
    };

    useEffect(() => {
        fetchPckgInfor();
    }, [])

    return (

        <PrimeReactProvider>
            <Toast ref={toast} />
            <Link to={'/dashboard/'+package_type+'/list'}>
                <Button className="backbutton">                
                        <img alt="logo" src={backbutton} className="h-2rem"></img>                
                </Button>
            </Link>
            <form onSubmit={formikUpdNF.handleSubmit} className="edit-form-first mb-40">
                <div className="form-field-group">
                    <span className="p-float-labell">
                        <label>Price</label><br />
                        <InputNumber name="pricetNF" value={formikUpdNF.values.pricetNF} onChange={(e) => {
                            formikUpdNF.setFieldValue('pricetNF', e.value);
                        }} />
                        <br />
                        <label>Disounted Price</label><br />
                        <InputNumber name="discountedPricetNF" value={formikUpdNF.values.discountedPricetNF} onChange={(e) => {
                            formikUpdNF.setFieldValue('discountedPricetNF', e.value);
                        }} />
                    </span>
                    <span className="p-float-labell">
                        <label>Days and Night</label><br />
                        <InputText name="dayNightNF" value={formikUpdNF.values.dayNightNF} onChange={(e) => {
                            formikUpdNF.setFieldValue('dayNightNF', e.target.value);
                        }} />
                    </span>
                </div>
                <div className="form-field-group">
                    <span className="p-float-labell">
                        <label>Number of Persons</label><br />
                        <InputNumber name="personsNF" value={formikUpdNF.values.personsNF} onChange={(e) => {
                            formikUpdNF.setFieldValue('personsNF', e.value);
                        }} />
                    </span>
                    <span className="p-float-labell">
                        <label>Location</label><br />
                        <InputText name="locationNF" value={formikUpdNF.values.locationNF} onChange={(e) => {
                            formikUpdNF.setFieldValue('locationNF', e.target.value);
                        }} />
                    </span>
                </div>
                <div className="form-field-group">
                <span className="p-float-labell">
                    <label>Overview</label><br />
                    <InputTextarea name="overviewNF" value={formikUpdNF.values.overviewNF} onChange={(e) => {
                        formikUpdNF.setFieldValue('overviewNF', e.target.value);
                    }} />
                </span>
                <span className="p-float-labell">
                    <label>Map Link</label><br />
                    <InputText name="mapLinkNF" value={formikUpdNF.values.mapLinkNF} onChange={(e) => {
                        formikUpdNF.setFieldValue('mapLinkNF', e.target.value);
                    }} />
                </span>
                </div>
                <div className="form-field-group">
                <span className="p-float-labell">
                    <label>Package Contact</label><br />
                    <InputText name="pckgContact" value={formikUpdNF.values.pckgContact} onChange={(e) => {
                        formikUpdNF.setFieldValue('pckgContact', e.target.value);
                    }} />
                </span>
                </div>
                <div className="form-button">
                    <Button type="submit" loading={loading} label="Update Package Information" />
                </div>
            </form>

            <PackageInformationIncluded showImageTemplate={showImageTemplate} />
            <PackageInformationTourPlan />
            <PackageListReview showImageTemplate={showImageTemplate} />
        </PrimeReactProvider>

    )

}