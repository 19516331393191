import React, { useState, useRef, useContext, useEffect  } from "react";  
import './custom.css';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import {gbData} from './globalVarbs';
import { CommonVariableContext } from './common';
import { redirect, useNavigate } from "react-router-dom";
import logo from './dashboardRoutes/images/Logo.png';

import "primeflex/primeflex.css";

export default function Login() {

    useEffect(() => {
        document.title = "RHAdventures Management"
    }, [])

    const { commonVariable, setCommonVariable } = useContext(CommonVariableContext);
    const navigate = useNavigate();

    if( commonVariable ){
        navigate("/dashboard");
    }

    const [ adminUserName, setAdminUserName ] = useState(null);
    const [ adminPassword, setAdminPassword ] = useState(null);

    const toast = useRef(null);

    const show = ( severity='success', summary='failed', toastMessage='') => {
        toast.current.show({ severity: severity, summary: summary, detail: toastMessage });
    };

    const formik = useFormik({
        initialValues: {
            adminUsername: 'rckhmladv9703',
            adminPassword:'rck78on7$$%7hNM'
        },
        validate: (data) => {
            let errors = {};

            if (!data.adminUsername) {
                errors.adminUsername = 'Username is required.';
            }

            if (!data.adminPassword) {
                errors.adminPassword = 'Password is required.';
            }

            return errors;
        },
        onSubmit: (data) => {
            // data && show(data);
            // console.log(data);

            fetch(gbData.API_URL+'/admin-login', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "uname":data.adminUsername,
                    "password":data.adminPassword
                }),
            })
            .then( response => {
                if( !response.ok ){
                    throw new Error( 'Network response was not ok' );
                }
                return response.json();
            })
            .then( ApiResponse => {
                // Process the newly created user data
                console.log('ApiResponse:', ApiResponse);
                if( ApiResponse.success == 0 ){
                    show( 'error', 'Failed', ApiResponse.message);
                }else if( ApiResponse.success == 1 ){
                    show( 'success', 'Success', ApiResponse.message);
                    setCommonVariable( true );
                    navigate("/dashboard");
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
            // formik.resetForm();
        }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    return (
        <PrimeReactProvider>

<div className="flex align-items-center justify-content-center login-outer-box bswn-admin-login bswn-log-section-1"> 
    <div className="surface-card p-4 shadow-5 border-round w-full lg:w-6">
        <div className="text-center mb-5">
            <img src={logo} alt="logo" height={50} className="mb-3" />
            <div className="text-900 text-3xl font-medium mb-3">Welcome to RockingHimachalAdventure Admin Panel</div>
            <span className="text-600 font-medium line-height-3">Login to Access your Dashboard</span>
        </div>

        <div>
            <form onSubmit={formik.handleSubmit} className="flex flex-column gap-2">
                <div className="login-field-wrapper">
                    <div className="login-field">
                        <span className="p-float-labell">
                            <Toast ref={toast} />
                            <InputText
                                placeholder="Admin - Username"
                                name="adminUsername"
                                value={formik.values.adminUsername}
                                onChange={(e) => { 
                                    formik.setFieldValue('adminUsername', e.target.value);
                                }}
                                className={classNames( 'w-full mb-3', { 'p-invalid': isFormFieldInvalid('adminUsername')})}
                            /> 
                            {/* <label htmlFor="input_adminUsername">Admin - Username</label> */}
                            {getFormErrorMessage('adminUsername')}  
                        </span>
                        
                    </div>  
                    <div className="login-field">
                        <span className="p-float-labell">
                            <Toast ref={toast} />
                            <InputText
                                placeholder="Admin - Password"
                                type="password"
                                name="adminPassword"
                                value={formik.values.adminPassword}
                                onChange={(e) => {
                                    formik.setFieldValue('adminPassword', e.target.value);
                                }}
                                className={classNames( 'w-full mb-3', { 'p-invalid': isFormFieldInvalid('adminPassword') })}
                            /> 
                            {/* <label htmlFor="input_adminPassword">Admin - Password</label> */}
                            {getFormErrorMessage('adminPassword')}  
                        </span>
                        
                    </div>   
                    <div className="login-field md:text-center">
                        <Button type="submit" label="Submit" />
                    </div>                
                </div>            
            </form>
        </div>
    </div>
</div>


            
        </PrimeReactProvider>
    )
}
