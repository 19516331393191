import React, { useState, useRef, useEffect } from "react";
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import "primeflex/primeflex.css";
import { Link } from "react-router-dom";
import crossicon from '../images/cross-button.jpg';
import pckgnmicon from '../images/pckgname.jpg';
import { gbData } from './../../globalVarbs';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';

export default function PackageTypeList(props) {

    // const isMounted = useRef(false);
    const [loading, setLoading] = useState(false);

    // toast and formik functions
        const toast = useRef(null);
        const showT = (severity = 'success', summary = 'failed', toastMessage = '') => {
            toast.current.show({ severity: severity, summary: summary, detail: toastMessage });
        };
    // end toast and formik functions

    var deletePackage = ( e, package_id, package_slug ) => {
        e.preventDefault()

        fetch( gbData.API_URL + '/admin-delete-package-type', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify( {
                "package_id": package_id,
                "package_slug": package_slug
            } ),
        } )
        .then( response => {
            if ( !response.ok ) {
                showT( 'error', 'Failed', 'Network response was not ok' );
                throw new Error( 'Network response was not ok' );
            }
            return response.json();
        })
        .then( ApiResponse => {
            // Process the newly created user data
            console.log( 'ApiResponse:', ApiResponse );
            if ( ApiResponse.success == 0 ) {
                showT( 'error', 'Failed', ApiResponse.message );
                return;
            }

            showT( 'success', 'Success', ApiResponse.message );

            props.fetchData();

        })
        .catch( error => {
            alert(error)
        });
    }

    useEffect(() => {
        props.fetchData();
    }, [])

    return (

        <PrimeReactProvider>

            <Toast ref={toast} position="top-center" />

            { 
                props.packageTypeList.length > 0 ?
                    <div className="bswn-packge-data">
                        {
                            props.packageTypeList.map((package_list, index) =>
                                <Link key={index} to={'/dashboard/' + package_list.package_slug + '/list'}>
                                    <div className="flex font-bold border-round bswn-packge-table" >
                                        <div className="pckgbox">
                                            <div className="db-pckg-details">
                                                <div className="db-pckg-details-left">
                                                    <div className="db-pckg-details-label">Package Name</div>
                                                    <div className="db-pckg-details-data">{package_list.package_name}</div>
                                                </div>
                                                <div className="db-pckg-details-right">
                                                    {/* <Button className="crossbutton" onClick={($this )=>deletePackage( $this, package_list.package_id, package_list.package_slug )} >
                                                        <img src={crossicon} alt="package-name-icon" />
                                                    </Button> */}
                                                </div>
                                            </div>
                                            <div className="db-pckg-detials-second">
                                                <div className="darkbg flex dbrowdata">
                                                    <div className="data-title">Lists In Package</div>
                                                    <div className="data-content">{package_list.pckg_list_count}</div>
                                                </div>
                                                <div className="lighttbg flex dbrowdata">
                                                    <div className="data-title">Categories In Package</div>
                                                    <div className="data-content">{package_list.pckg_categ_count}</div>
                                                </div>
                                                <div className="darkbg flex dbrowdata">
                                                    <div className="data-title">Tags In Package</div>
                                                    <div className="data-content">{package_list.pckg_tag_count}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            )
                        }
                    </div>
                : 
                    <p>{props.packageTypeListLoadingStatus}</p>
            }

        </PrimeReactProvider>

    );
}