import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { gbData } from '../../globalVarbs';
import { PrimeReactProvider } from 'primereact/api';
import "primeflex/primeflex.css";
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { Link } from "react-router-dom";



import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import backbutton from '../images/back-button.png';

export default function PackageGallery() {

    let { package_type } = useParams();
    let { package_list_slug } = useParams();
    const [GlryImageCst, setGlryImageCst] = useState();
    const [listGLInfor, setListGLInfor] = useState();
    const [loading, setLoading] = useState(false);

    const GalleryOptions = [
        { name: 'Main', code: 'main' },
        { name: 'First Four', code: 'firstfour' },
        { name: 'Normal', code: 'normal' },
        { name: 'card', code: 'card' }
    ];

    const [formData, setFormData] = useState({
        image: null,
        text: '',
    });

    const handleChange = (event) => {
        const { name, value, files } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: name === 'image' ? files[0] : value,
        }));
    };


    const toast = useRef(null);
    const showT = ( severity = 'success', summary = 'failed', toastMessage = '' ) => {
        toast.current.show( { severity: severity, summary: summary, detail: toastMessage } );
    };
    const isFormFieldInvalid = ( name ) => !!( formikGL.touched[ name ] && formikGL.errors[ name ] );

    const getFormErrorMessage = ( name ) => {
        return isFormFieldInvalid( name ) ? <small className="p-error">{ formikGL.errors[ name ] }</small> : <small className="p-error">&nbsp;</small>;
    };

    const fetchPckgGLInfor = async () => {
        try {
            const response = await fetch( gbData.API_URL + '/admin-get-package-list-gallery', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "pckg_list_slug": package_list_slug
                }),
            });

            if ( !response.ok )  {
                showT( 'error', 'Failed', `API request failed with status ${response.status}` );
                throw new Error( `API request failed with status ${response.status}` );
            }

            const respData = await response.json();
            // console.log('API response:', respData);
            setListGLInfor(respData.data)
            // Handle successful API response here
        } catch (error) {
            alert(error)
            // console.error('Error:', error);
            // Handle errors here
        } finally {
            // Optionally, clear the form after submission
            //   setFormData([{ id: 1, image: null, text: '' }]);
        }
    }

    const formikGL = useFormik({
        initialValues: {
            glryImage: '',
            glryImageType: '',
        },
        validate: ( data ) => {
            let errorsUpd = {};

            if ( !data.glryImage ) {
                errorsUpd.glryImage = 'Gallery Image is required.';
            }

            if ( !data.glryImageType ) {
                errorsUpd.glryImageType = 'Gallery Image Type is required.';
            }

            return errorsUpd;
        },
        onSubmit: ( data ) => {
            // data && showT(data);
            // console.log(data);
            setLoading(true);
            const formDataToSend = new FormData();
            formDataToSend.append( 'list_slug', package_list_slug );
            formDataToSend.append( 'list_packg_type', package_type );
            formDataToSend.append( 'gallery_image', GlryImageCst );
            formDataToSend.append( 'gallery_image_type', data.glryImageType.code );
            //   return;

            fetch( gbData.API_URL + '/admin-create-package-list-gallery', {
                method: 'POST',
                body: formDataToSend
            })
            .then( response => {
                if ( !response.ok ) { 
                    showT('error', 'Failed', 'Network response was not ok' );
                    throw new Error( 'Network response was not ok' );
                    setLoading(false);
                } 
                return response.json();
            })
            .then( ApiResponse => { 
                // Process the newly created user data
                // console.log('ApiResponse:', ApiResponse);
                if ( ApiResponse.success == 0 ) {
                    showT( 'error', 'Failed', ApiResponse.message );
                    setLoading(false);
                    return;
                }
                //   setVisible(false);
                formikGL.resetForm();
                showT( 'success', 'Success', ApiResponse.message );
                setLoading( false );
                fetchPckgGLInfor();
            })
            .catch(error => {
                alert(error)
                // showT( 'error', 'Failed', error );
                // console.error('Error:', error);
                setLoading(false);
            });
            formikGL.resetForm();
            // setLoading(true);
        }
    });

    const deleteGLInfor = async (e,rowData) => {
        // console.group(e);
        e.target.innerHTML = "Deleting...";

        const formDataToSend = new FormData();
        formDataToSend.append( 'id', rowData.id );

        try {
            const response = await fetch( gbData.API_URL + '/admin-delete-package-list-gallery', {
                method: 'POST',
                body: formDataToSend,
            });

            if ( !response.ok ) {
                showT( 'error', 'Failed', `API request failed with status ${response.status}` );
                throw new Error( `API request failed with status ${response.status}` );
                e.target.innerHTML = "Delete";
            }

            const data = await response.json();
            // console.log('API response:', data);
            showT( 'success', 'Success', data.message );
            fetchPckgGLInfor();
            e.target.innerHTML = "Delete";
            // Handle successful API response here
        } catch (error) {
            alert(error)
            // console.error('Error:', error);
            e.target.innerHTML = "Delete";
            // Handle errors here
        } finally {
            // Optionally, clear the form after submission
            // setFormData({ image: '', text: '' });
        }
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button label="Delete" outlined className="mr-1" onClick={(e) => deleteGLInfor( e,rowData )} />
            </React.Fragment>
        );
    };

    const showImageTemplate = (rowData) => {
        var $image_path;
        $image_path = gbData.LV_URL + rowData.gallery_image;
        return (
            <React.Fragment>
                <Image src={$image_path} alt="Image" width="40" height="40" preview />
            </React.Fragment>
        );
    };

    useEffect(() => {
        fetchPckgGLInfor();
    }, [])

    return (

        <PrimeReactProvider>
            <Toast ref={toast} />
            <Link to={'/dashboard/'+package_type+'/list'}>
                <Button className="backbutton">                
                        <img alt="logo" src={backbutton} className="h-2rem"></img>                
                </Button>
            </Link>
            <div className="gallery-submit-form">
                <form onSubmit={formikGL.handleSubmit} className='bswn-gallery-form'>
                    <div className='bswn-upload-image'>
                        <label htmlFor="image">Gallery Image: </label>
                        <input
                            type="file"
                            id="glry-Image"
                            name="glryImage"
                            value={formikGL.values.glryImage}
                            onChange={(e) => {
                                formikGL.setFieldValue('glryImage', e.target.value);
                                setGlryImageCst(e.currentTarget.files[0])
                            }}
                            className={classNames('w-full bswn-upload-field', { 'p-invalid': isFormFieldInvalid('glryImage') })}
                            accept="image/jpeg, image/png, image/gif"
                        />
                        {getFormErrorMessage('glryImage')}
                    </div>
                    <div className='gallery-bottom-with-submit-buton'>
                        <div className='bottom-title'>
                            <label htmlFor="text">Gallery Image Type: </label>
                        </div>
                        <div className='bottome-gallry-drop-down'>
                            <Dropdown
                                inputId="glryImageType"
                                name="glryImageType"
                                value={formikGL.values.glryImageType}
                                options={GalleryOptions}
                                optionLabel="name"
                                placeholder="Select Image Type"
                                onChange={(e) => {
                                    formikGL.setFieldValue('glryImageType', e.value);
                                }}
                            />
                            {getFormErrorMessage('glryImageType')}
                        </div>
                    </div>
                    <Button type="submit" label="Add Gallery Image" loading={loading} className='button-bottom-gallry' />
                </form>
            </div>
            <DataTable value={listGLInfor} tableStyle={{ minWidth: '50rem' }}  >
                <Column field={showImageTemplate} header="Gallery Image"></Column>
                <Column field="gallery_image_type" header="Image Typee"></Column>
                <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
            </DataTable>
            
        </PrimeReactProvider>
    )
}
