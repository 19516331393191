import React from "react";
import admin_profile from './images/admin-image.jpg';
function Admin_Headers( props ){
    return(
       <div className="admin-header">
        <div className="admin-profile-pic">
           <img src={admin_profile} alt="admin-profile-picture"/>
        </div>
        <div className="admin-setting">
            <select className="setting-dropdown">
                <option>Rittam Thakur</option>
                <option onClick={()=>props.redirect_login()}>Sign Out</option>
            </select>
        </div>
        </div>
    )
}
export default Admin_Headers;