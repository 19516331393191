import React, { useState, useRef, useEffect } from 'react';
import { Routes, Route, useParams, Navigate, useNavigate } from 'react-router-dom';

import { gbData } from '../../globalVarbs';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import "primeflex/primeflex.css";
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';

import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';

export default function PackageInformationTourPlan() {

    let { package_type } = useParams();
    let { package_list_slug } = useParams();
    const imageRef = useRef();
    const [listTPInfor, setListTPInfor] = useState("");
    const [includeImage, setIncludeImage] = useState("");
    const [includeText, setIncludeText] = useState("");
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        image: null,
        text: '',
    });

    const handleChange = (event) => {
        const { name, value, files } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: name === 'image' ? files[0] : value,
        }));
    };


    const toast = useRef(null);
    const showT = (severity = 'success', summary = 'failed', toastMessage = '') => {
        toast.current.show({ severity: severity, summary: summary, detail: toastMessage });
    };
    const isFormFieldInvalid = (name) => !!(formikTP.touched[name] && formikTP.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formikTP.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    const fetchPckgTPInfor = async () => {
        try {
            const response = await fetch(gbData.API_URL + '/admin-get-package-tp-information', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "pckg_list_slug": package_list_slug
                }),
            });

            if (!response.ok) {
                showT('error', 'Failed', `API request failed with status ${response.status}` );
                throw new Error( `API request failed with status ${response.status}` );
            }

            const respData = await response.json();
            console.log('API response:', respData);
            setListTPInfor(respData.data)
            // Handle successful API response here
        } catch (error) {
            alert(error)
            // console.error('Error:', error);
            // Handle errors here
        } finally {
            // Optionally, clear the form after submission
            //   setFormData([{ id: 1, image: null, text: '' }]);
        }
    }

    const formikTP = useFormik({
        initialValues: {
            tpTitle: '',
            tpDetail: '',
            tpDay: '',
            tpSort: ''

        },
        validate: ( data ) => {
            let errorsUpd = {};

            if ( !data.tpTitle ) {
                errorsUpd.tpTitle = 'Tour Plan Title is required.';
            }

            if ( !data.tpDetail ) {
                errorsUpd.tpDetail = 'Tour Plan Detail is required.';
            }

            if ( !data.tpDay ) {
                errorsUpd.tpDay = 'Tour Plan Day is required.';
            }

            if ( !data.tpSort ) {
                errorsUpd.tpSort = 'Tour Plan Sort is required.';
            }

            return errorsUpd;
        },
        onSubmit: (data) => {
            // data && showT(data);
            // console.log(data);
            // return;
            setLoading(true);

            fetch( gbData.API_URL + '/admin-create-package-tp-information', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "tp_title": data.tpTitle,
                    "tp_detail": data.tpDetail,
                    "tp_day": data.tpDay,
                    "tp_sort": data.tpSort,
                    "pckgListSlug": package_list_slug,
                    "pckgListPckgType": package_type
                }),
            })
            .then( response => {
                if ( !response.ok ) {
                    showT('error', 'Failed', 'Network response was not ok' );
                    throw new Error( 'Network response was not ok' );
                    setLoading(false);
                }
                return response.json();
            })
            .then( ApiResponse => {
                // Process the newly created user data
                // console.log('ApiResponse:', ApiResponse);
                if ( ApiResponse.success == 0 ) {
                    showT( 'error', 'Failed', ApiResponse.message );
                    setLoading( false );
                    return;
                }
                //   setVisible(false);
                formikTP.resetForm();
                showT( 'success', 'Success', ApiResponse.message );
                setLoading(false);
                fetchPckgTPInfor();
            })
            .catch(error => {
                alert(error)
                // console.error('Error:', error);
            });
            // formik.resetForm();
        }
    });

    const deleteTPInfor = async (e,rowData) => {
        console.group(rowData);
        e.target.innerHTML = "Deleting...";

        const formDataToSend = new FormData();
        formDataToSend.append('id', rowData.id);

        try {
            const response = await fetch( gbData.API_URL + '/admin-delete-package-tp-information', {
                method: 'POST',
                body: formDataToSend,
            });

            if ( !response.ok ) {
                showT( 'success', 'Success', `API request failed with status ${response.status}` );
                throw new Error( `API request failed with status ${response.status}` );
                e.target.innerHTML = "Delete";
            }

            const data = await response.json();
            // console.log('API response:', data);
            showT( 'success', 'Success', data.message );
            fetchPckgTPInfor();
            // Handle successful API response here
        } catch ( error ) {
            alert(error)
            // console.error('Error:', error);
            e.target.innerHTML = "Delete";
            // Handle errors here
        } finally {
            // Optionally, clear the form after submission
            setFormData({ image: '', text: '' });
        }
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button label="Delete" outlined className="mr-1" onClick={(e) => deleteTPInfor(e,rowData)} />
            </React.Fragment>
        );
    };

    const onCellEditComplete = (e) => {
        let { rowData, newValue, field, originalEvent: event } = e;
    };

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };
    const onRowEditComplete = async (e) => {
        console.log(e)
        const formDataToSend = new FormData();
        formDataToSend.append('sort_for', 'tour_plan');
        formDataToSend.append('id', e.newRowData.id);
        formDataToSend.append('tp_sort', e.newValue);

        try {
            const response = await fetch( gbData.API_URL + '/admin-package-sort-update', {
                method: 'POST',
                body: formDataToSend,
            });

            if (!response.ok) {
                throw new Error(`API request failed with status ${response.status}`);
            }

            const data = await response.json();
            console.log( 'API response:', data );
            showT( 'success', 'Success', data.message );
            // fetchPckgRV();/
            // Handle successful API response here
        } catch (error) {
            // console.error( 'Error:', error );
            alert(error)
            // Handle errors here
        } finally {
            // Optionally, clear the form after submission
            // setFormData({ image: '', text: '' });/
        }
    };

    useEffect(() => {
        fetchPckgTPInfor();
    }, [])

    return (

        <PrimeReactProvider>
            <Toast ref={toast} />
            <DataTable value={listTPInfor} tableStyle={{ minWidth: '50rem' }} dataKey="id">
                <Column field="tp_day" header="TP Title"></Column>
                <Column field="tp_title" header="TP Title"></Column>
                <Column field="tp_detail" header="TP Detail"></Column>
                <Column key='id' field="tp_sort" header="TP Sort" editor={(options) => textEditor(options)} onCellEditComplete={onRowEditComplete}></Column>
                <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
            </DataTable>
            <form onSubmit={formikTP.handleSubmit} className='edit-form-third mt-40 mb-40'>
                <div className='form-field-group'>
                    <div className='form-left-sec'>
                        <label htmlFor="image">TP Day: </label><br />
                        <input
                            type="text"
                            id="tpDay"
                            name="tpDay"
                            value={formikTP.values.tpDay}
                            onChange={(e) => {
                                formikTP.setFieldValue('tpDay', e.target.value);
                            }}
                            className={classNames('w-full mb-3 p-inputtext p-component', { 'p-invalid': isFormFieldInvalid('tpDay') })}
                        />
                        {getFormErrorMessage('tpDay')}
                    </div>
                    <div className='form-left-sec'>
                        <label htmlFor="image">TP Title: </label><br />
                        <input
                            type="text"
                            id="tpTitle"
                            name="tpTitle"
                            value={formikTP.values.tpTitle}
                            onChange={(e) => {
                                formikTP.setFieldValue('tpTitle', e.target.value);
                            }}
                            className={classNames('w-full mb-3 p-inputtext p-component', { 'p-invalid': isFormFieldInvalid('tpTitle') })}
                        />
                        {getFormErrorMessage('tpTitle')}
                    </div>
                    <div className='form-right-sec'>
                        <label htmlFor="text">TP Detail: </label><br />
                        <textarea
                            id="tpDetail"
                            name="tpDetail"
                            value={formikTP.values.tpDetail}
                            onChange={(e) => {
                                formikTP.setFieldValue('tpDetail', e.target.value);
                            }}
                            className={classNames('w-full mb-3 p-inputtext p-component', { 'p-invalid': isFormFieldInvalid('tpDetail') })}
                        />
                        {getFormErrorMessage('tpDetail')}
                    </div>
                    <div className="form-field-group">
                        <span className="p-float-labell1">
                            <label htmlFor="text">TP Sort: </label><br/>
                            <InputNumber
                                id="tpSort"
                                name="tpSort"
                                value={formikTP.values.tpSort}
                                onChange={(e) => {
                                    formikTP.setFieldValue('tpSort', e.value);
                                }}
                                className={classNames('w-full mb-3 p-inputtext p-component', { 'p-invalid': isFormFieldInvalid('tpSort') })}
                            />
                            {getFormErrorMessage('tpSort')}
                        </span>     
                    </div>
                </div>
                <div className="form-button">
                    <Button type="submit" label="Add TP Information" loading={loading} className='p-button p-component p-button-label' />
                </div>

            </form>
        </PrimeReactProvider>
    )
}
