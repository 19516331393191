import React, { useState, useRef, useEffect } from "react"; 
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import "primeflex/primeflex.css";
import {gbData} from '../../globalVarbs';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { Routes, Route, useParams } from 'react-router-dom';

export default function AddPackageTag() {

//   console.log(gbData.API_URL);

    let { package_type } = useParams();

    const [visible, setVisible] = useState(false);
    const [position, setPosition] = useState('center');
    const [ pckgTagName, setPckgTaName ] = useState(null);
    const [ pckgTagSlug, setPckgTaSlug ] = useState(null);
    const [ packageTypeList, setPackageTypeList ] = useState( [] );
    const [ packageTypeListLoadingStatus, setPackageTypeListLoadingStatus ] = useState( 'Loading List' );
    const [loading, setLoading] = useState(false);


    const fetchData = async () => {
        await fetch( gbData.API_URL+'/admin-get-package-type-tag', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "tag_pckg_slug":package_type
            }),
        })
        .then( response => {
            if( !response.ok ){
                showT( 'error', 'Failed', 'Network response was not ok' );
                throw new Error( 'Network response was not ok' );
            }
            return response.json();
        })
        .then( ApiResponse => {
            // Process the newly created user data
            console.log('ApiResponse:', ApiResponse);
            if( ApiResponse.success == 0 ){
                showT( 'error', 'Failed', ApiResponse.message );
                return;
            }        
            if( ApiResponse.data.length < 1 ){
                setPackageTypeListLoadingStatus( "No Package Type Found" );
            }else{
                setPackageTypeList( ApiResponse.data );                
            }  
        })
        .catch(error => {
            alert(error)
            // console.error('Error:', error);
        });   
    }

    const toast = useRef(null);

    const showT = ( severity='success', summary='failed', toastMessage='') => {
        toast.current.show({ severity: severity, summary: summary, detail: toastMessage });
    };

    const formik = useFormik({
        initialValues: {
            pckgTagName: '',
            pckgTagSlug:''
        },
        validate: (data) => {
            let errors = {};

            if ( !data.pckgTagName ) {
                errors.pckgTagName = 'Package Tag Name is required.';
            }

            if ( !data.pckgTagSlug ) {
                errors.pckgTagSlug = 'Package Tag Slug is required.';
            }

            return errors;
        },
        onSubmit: ( data ) => {
            // data && showT(data);
            // console.log(data);
            // return;
            setLoading( true );

            fetch( gbData.API_URL+'/admin-create-package-tag', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "pck_tag_name":data.pckgTagName,
                    "pck_tag_slug":data.pckgTagSlug,
                    "pck_type":package_type
                }),
            })
            .then( response => {
                if( !response.ok ){
                    showT( 'error', 'Failed', 'Network response was not ok' );
                    throw new Error( 'Network response was not ok' );
                    setLoading(false);
                }
                return response.json();
            })
            .then( ApiResponse => {
                // Process the newly created user data
                console.log('ApiResponse:', ApiResponse);
                if( ApiResponse.success == 0 ){
                    showT( 'error', 'Failed', ApiResponse.message );
                    setLoading(false);
                    return;
                }
                //   setVisible(false);
                formik.resetForm();
                showT( 'success', 'Success', ApiResponse.message );              
                setLoading(false);
                fetchData();
            })
            .catch(error => {
                alert(error)
                // console.error('Error:', error);
            });
            // formik.resetForm();
        }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    const showD = (position) => {
        setPosition(position);
        setVisible(true);
    };
    
    const headerElement  = (
        <div>
            <h1>Add Package Tag</h1>
        </div>
    );

    const deletePackageTag = ( e,rowData ) => {
        // console.log(rowData);return;
        e.target.innerHTML = "Deleting...";
        fetch( gbData.API_URL + '/admin-edlete-package-type-tag', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "package_tag_slug": rowData.tag_slug,
                "package_slug": rowData.tag_package
            }),
        })
        .then( response => {
            if ( !response.ok ) {
                showT( 'error', 'Failed', 'Network response was not ok' );
                throw new Error( 'Network response was not ok' );
                e.target.innerHTML = "Delete";
            }
            return response.json();
        })
        .then( ApiResponse => {
            // Process the newly created user data
            // console.log('ApiResponse:', ApiResponse);
            if ( ApiResponse.success == 0 ) {
                showT( 'error', 'Failed', ApiResponse.message );
                e.target.innerHTML = "Delete";
                return;
            }
            
            fetchData();
            showT( 'success', 'Success', ApiResponse.message );
        })
        .catch( error => {
            alert(error)
            // console.error('Error:', error);
            e.target.innerHTML = "Delete";
        });
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button label="Delete" outlined className="mr-1" onClick={(e)=>deletePackageTag(e,rowData)} />
            </React.Fragment>
        );
    };

    useEffect(() =>{
        fetchData();
    },[])

  return (
    <PrimeReactProvider>
        
        <Toast ref={toast} />
        <div className='db-pckg-type-wrapper'>
            <div className="pckg-type-button">
            <Button label="Add Package Tag" icon="pi pi-external-link" onClick={() => showD('top')}  />
            </div>
            <Dialog header={headerElement} visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)} position={position}>
                <div className="m-0">
                  <form onSubmit={formik.handleSubmit} className="flex flex-column gap-2 add-pkg-pop-form">
                    <div className="login-field-wrapper">
                        <div className="login-field">
                            <span className="p-float-labell">
                                <InputText
                                    placeholder="PackageTag - Name"
                                    name="pckgTagName"
                                    value={formik.values.pckgTagName}
                                    onChange={(e) => {
                                        formik.setFieldValue('pckgTagName', e.target.value);
                                    }}
                                    className={classNames( 'w-full mb-3', { 'p-invalid': isFormFieldInvalid('pckgTagName')})}
                                /> 
                                {/* <label htmlFor="input_adminUsername">Admin - Username</label> */}
                                {getFormErrorMessage('pckgTagName')}  
                            </span>
                            
                        </div>  
                        <div className="login-field">
                            <span className="p-float-labell">
                                <InputText
                                    placeholder="PackageTag - Slug"
                                    name="pckgTagSlug"
                                    value={formik.values.pckgTagSlug}
                                    onChange={(e) => {
                                        formik.setFieldValue('pckgTagSlug', e.target.value.replace(/ /g, "_"));
                                    }}
                                    className={classNames( 'w-full mb-3', { 'p-invalid': isFormFieldInvalid('pckgTagSlug') })}
                                /> 
                                {/* <label htmlFor="input_adminPassword">Admin - Password</label> */}
                                {getFormErrorMessage('pckgTagSlug')}  
                            </span>
                            
                        </div>   
                        <div className="login-field">
                            <Button type="submit" label="Add Package tag" loading={loading} />
                        </div>                
                    </div>            
                  </form>
                </div>
            </Dialog>

            <div className="card">
                <DataTable value={packageTypeList}  tableStyle={{ minWidth: '50rem' }}>
                <Column field="tag_name" header="Tag Name"></Column>
                    <Column field="tag_package" header="Tag Package"></Column>
                    <Column field="tag_slug" header="Tag Slug"></Column>        
                    <Column header="Action" body={actionBodyTemplate}></Column>            
                </DataTable>
            </div>

        </div>
    </PrimeReactProvider>
  );
}